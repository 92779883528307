import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'
import NProgress from "@/config/nprogress"; //导航页面加载进度条
// import { showFullScreenLoading, tryHideFullScreenLoading } from "@/config/serviceLoading"; //辅助函数和枚举

const routes = [
  {
    path: '/',
    name: '首页',
    component: () => import('../layout'),
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: '首页',
        component: () => import('../views/index')
      },
      {
        path: '/userCenter',
        name: '个人中心',
        component: () => import('../views/userCenter/index')
      },
      {
        path: '/financial',
        name: '加油卡管理 / 车队信息 / 财务管理',
        component: () => import('../views/member/financial/index')
      },
      {
        path: '/memberCard',
        name: '加油卡管理 / 车队信息 / 成员卡设置',
        component: () => import('../views/member/financial/memberCard')
      },
      {
        path: '/fleetInfo',
        name: '加油卡管理 / 车队信息 / 车队管理',
        component: () => import('../views/member/fleetInfo/index.vue')
      }


    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('@/views/error/404.vue'),
  },


]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = store.getters.GET_STATIONNAME
  NProgress.start()
  // showFullScreenLoading()
  next()



  NProgress.done()
  // tryHideFullScreenLoading()
})
// router.beforeEach((to, from, next) => {
//   if (to.matched.length == 0) { router.push(to.path); }
//   if (to.path === "/login") {
//     next();
//   } else {

//     const token = store.getters
//     console.log(token)
//     if (token === undefined || token === "") {
//       next("/login");
//     } else {
//       next();
//     }
//   }
// });


export default router
