import { get, post } from "../request";
/* 统计  日报 模块*/
export default {
  /**
   * @description 查询当前日期
   * @param {String} params
   * @returns Object
   */
  getSelectNewDay(params) {
    return get(`/newStatistics/statis?${params}`);
  },
  /**
   * @description 查询当前班次
   * @returns Object
   */
  getSelectShift() {
    return get(`/shift/selectAll`,{},true);
  },

  /**
   * @description 查询具体时间(按日,按班次)
   * @param {String} params
   * @returns Object
   */
  getSelectTime(params) {
    return get(`/newStatistics/selectTime?${params}`);
  },
  /**
   * @description 查询支付通道入账明细
   * @returns Object
   */
  getSelectAccesDetail(params) {
    return get(`/payServiceOrder/selectAll`, params);
  },
};
