import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "@/assets/styles/border.css";
import "@/assets/styles/reset.css";
import "@/router/permission.js";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import lang from "element-plus/lib/locale/lang/zh-cn"; // 引入中文语言包
import "dayjs/locale/zh-cn"; // 引入 dayjs 的中文语言包

import api from "@/util/api";
import code from "@/config/codeConfig"; //状态码

const app = createApp(App);
app.use(ElementPlus, { locale: lang });

app.provide("$api", api); // 提供全局引用 api 方法
app.provide("$code", code);
app.provide('pageSize',[10, 20, 30, 50, 100, 200, 500,1000,2000])// 表格页码

app.use(store).use(router);

/**自定义全局按钮权限指令 */
app.directive("z-auth", {
  // 指令的定义对象
  beforeMount(el, binding, vnode) {
    // `el` 是指令绑定的 DOM 元素
    // `binding` 是指令的绑定信息
    // `vnode` 是虚拟 DOM 节点
    const auth = binding.value; // 获取绑定的值
    const authString = store.getters.GET_AUTHSTRING(auth); // 从 store 获取权限字符串

    // 在这里可以进行权限验证逻辑
    // console.log({ el });
    if (authString !== true) {
      el.style.display = "none"; // 例如，隐藏没有权限的元素
    }
  },
});

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.mount("#app");
